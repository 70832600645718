import React from 'react';
import {createRoot} from 'react-dom/client';
import {LightTheme} from '@verily-src/react-design-system';
import {ThemeProvider} from '@mui/material';
import TaskListApplication from './task-list-application';

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <React.StrictMode>
      <ThemeProvider theme={LightTheme}>
        <TaskListApplication />
      </ThemeProvider>
    </React.StrictMode>
  );
}
